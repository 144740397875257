import React                                                                         from 'react';
import { BackgroundImage, Badge, Card, Group, Stack, Text, Title }                   from '@mantine/core';
import { IconBath, IconBed, IconHotelService, IconMoonStars, IconRuler, TablerIcon } from '@tabler/icons';
import { NextLink }                                                                  from '@mantine/next';
import { AppRoutes, getPageRoute }                                                   from '@core/router/AppRoutes';
import { FavButton }                                                                 from '@features/common/FavButton';
import { formatTitle, formatTitleNoS }                                               from '@utils/dxb-utils';
import { Details, Settings }                                                         from '../../../../generated/graphql';
import useStyles                                                                     from './PropertyItemCard.styles';
import {useBookingState} from "@core/providers/BookingStateProvider";


interface Feature {
    label: string;
    value: number;
    icon: TablerIcon;
}

interface Props {
    propertyId: string;
    name: string;
    address: string;
    thumb: string;
    amount: number;
    currency: string;
    details: Details;
    isFeatured?: boolean;
    isPropertyPage?: boolean;
    isFavVisible?: boolean;
    isFav?: boolean;
    settings?: Settings;
}

/**
 * IMPL IN:
 *
 * FeaturedScroller
 * PropertiesLanding
 * SearchLanding
 *
 * */
export const PropertyItemCard: React.FC<Props> = ({
                                                      propertyId,
                                                      name,
                                                      address,
                                                      thumb,
                                                      amount,
                                                      currency,
                                                      details,
                                                      isFeatured,
                                                      isPropertyPage,
                                                      isFavVisible,
                                                      isFav,
                                                      settings
                                                  }) => {

    const { classes } = useStyles();

    const { resetBookingInfo, resetPrices } = useBookingState(); // LOCAL CALCULATIONS

    /*const featureDetailsData: Feature[] = [
        { label: formatTitle(details?.bed_rooms, 'Bedroom'), value: details.bed_rooms, icon: IconBed },
        { label: formatTitle(details?.bath_rooms, 'Bathroom'), value: details.bath_rooms, icon: IconBath },
        { label: formatTitle(details?.max_occupancy, 'Guest'), value: details.max_occupancy, icon: IconHotelService },
    ];*/

    const featureDetailsFirstData: Feature[] = [
        { label: formatTitle(details?.bed_rooms, 'Bed'), value: details.bed_rooms, icon: IconBed },
        { label: formatTitle(details?.bath_rooms, 'Bath'), value: details.bath_rooms, icon: IconBath },
        { label: formatTitle(details?.max_occupancy, 'Guest'), value: details.max_occupancy, icon: IconHotelService },
    ];

    const featureDetailsFirst = featureDetailsFirstData.map((feature) =>
        <Stack key={feature.label} align="left">
            <Text className={classes.features}>
                <feature.icon size={26} stroke={1.1} className={classes.icon}/>
                <span className={classes.textNumber}>{feature.value}</span>
                {/*<span className={classes.textHidden}>{feature.label}</span>*/}
                <span>{feature.label}</span>
            </Text>
        </Stack>
    );

    const featureDetailsSecondData: Feature[] = [
        { label: 'm²', value: details.size, icon: IconRuler },
        { label: formatTitleNoS(settings.minimumNights, 'Nights Minimum'), value: settings.minimumNights, icon: IconMoonStars },
    ];

    const featureDetailsSecond = featureDetailsSecondData.map((feature) =>
        <Stack key={feature.label} align="left">
            <Text className={classes.features}>
                <feature.icon size={26} stroke={1.1} className={classes.icon}/>
                <span className={classes.textNumber}>{feature.value}</span>
                {/*<span className={classes.textHidden}>{feature.label}</span>*/}
                <span>{feature.label}</span>
            </Text>
        </Stack>
    );

    function resetInternalState() {
        resetPrices();
        resetBookingInfo()
    }

    return (
        <Card radius="xs" className={isPropertyPage ? classes.propertyItemCard : classes.featuredScrollerCard}>

            <Card.Section className={classes.cardTopSection}>

                <BackgroundImage
                    className={classes.cardBackgroundImage}
                    title={name}
                    radius="xs"
                    src={thumb || './images/placeholder-600x300.jpg'}
                    component={NextLink}
                    onClick={() => {
                        resetInternalState();
                    }}
                    href={getPageRoute(AppRoutes.PROPERTIES, settings.slug)} target="_self"/>

                {/*KEEP IT HERE*/}
                {/*<Image src={thumb} alt={'Apartment Image'} withPlaceholder={!thumb} fit="cover" />*/}

                {isFeatured &&
                    <Badge className={classes.featuredBadge} radius="xs" variant="filled">
                        {/*<IconStar fill="white" size={24} stroke={1}/>*/}
                        <Text className={classes.featuredBadgeText}>Featured</Text>
                    </Badge>
                }

                <Group className={classes.priceGroup}>

                    <Text style={{ flex: 2 }}>
								<span className={classes.priceCurrency}>
									{currency}
								</span>
                        <span className={classes.priceAmount}>
									{amount}
								</span>
                        <span className={classes.priceDuration}>
									/ night
								</span>
                    </Text>

                    {isFavVisible && <FavButton propertyId={propertyId} isFav={isFav} />}

                </Group>

            </Card.Section>

            <Card.Section pt={10} pb={0} className={classes.cardBottomSection}>

                <Text
                    className={classes.propertyCardTitle}
                    title={name}
                    component={NextLink}
                    onClick={() => {
                        resetInternalState();
                    }}
                    href={getPageRoute(AppRoutes.PROPERTIES, settings.slug)}
                    target="_self"
                    m={0}
                    weight={400}
                    lineClamp={1}
                >
                    {name}
                </Text>

                <Group className={classes.titleLocation}>
                    <Title lineClamp={1} className={classes.h2} order={2}>{address}</Title>
                </Group>

            </Card.Section>

            <Card.Section pt={10} pb={0} className={classes.cardFeaturesSectionTop}>

                <Group spacing={16} position="left">
                    {featureDetailsFirst}
                </Group>

            </Card.Section>

            <Card.Section pt={10} pb={20} className={classes.cardFeaturesSectionBottom}>

                <Group spacing={20} position="left">
                    {featureDetailsSecond}
                </Group>

            </Card.Section>

        </Card>
    );

};
