import { createStyles } from '@mantine/styles';


/**
 * This is where we are handling styling for
 * property card - in featured scroller and properties page grid
 *
 * */

export default createStyles((theme: {
    colors: { [x: string]: any[] };
    white: string;
    fontSizes: any;
    spacing: any;
    radius: any;
    fn: any;
    black: any;
    shadows: any;
    colorScheme: string;
}, _params, getRef) => {

    // const image = getRef('image');

    return {

        mainCard: {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
            borderStyle: 'solid',
            borderWidth: 1,
        },

        featuredScrollerCard: {
            // minWidth: 320,
            border: 'none',
            marginBottom: theme.spacing.xs,
            marginTop: theme.spacing.xs,
            boxShadow: 'none',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
            borderStyle: 'solid',
            borderWidth: 1,

            /*[theme.fn.smallerThan('sm')]: {
                borderLeft: 0,
                borderRight: 0,
                borderTop: 0,
                borderBottom: 1,
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
                borderStyle: 'dashed',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },*/
        },

        propertyItemCard: {
            // minWidth: 320,
            border: 'none',
            marginBottom: theme.spacing.xs,
            marginTop: theme.spacing.xs,
            boxShadow: 'none',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
            borderStyle: 'solid',
            borderWidth: 1,
        },

        cardTopSection: {
            borderRadius: theme.radius.xs,
            display: 'flex',
            height: 350,
            overflow: 'hidden',
            // alignItems: 'center',
            // justifyContent: 'center',
            borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
        },

        cardBackgroundImage: {
            transition: 'box-shadow 150ms ease-in-out, transform 100ms ease-in-out',

            ':hover': {
                transform: 'scale(1.05)',
                transition: 'all .5s',
            },
        },

        label: {
            marginBottom: theme.spacing.xs,
            marginTop: theme.spacing.xs,
            lineHeight: 1,
            fontWeight: 400,
            fontSize: theme.fontSizes.xs,
            letterSpacing: -0.25,
            textTransform: 'uppercase',
        },

        cardBottomSection: {
            padding: theme.spacing.md,
        },

        cardFeaturesSectionTop: {
            padding: theme.spacing.md,

            [theme.fn.smallerThan('sm')]: {
                display: 'flex',
                justifyContent: 'center',
            },
        },

        cardFeaturesSectionBottom: {
            padding: theme.spacing.md,

            [theme.fn.smallerThan('sm')]: {
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 30,
            },
        },

        featuredBadge: {
            position: 'absolute',
            width: 'fit-content',
            height: 32,
            lineHeight: 1,
            padding: 10,
            top: 10,
            left: 10,
            fontWeight: 400,
            fontSize: 14,
            textTransform: 'capitalize',
        },

        featuredBadgeText: {
            color: theme.white,
        },

        /*icon: {
            marginLeft: -3,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
        },*/

        /*featuresText: {
            marginTop: 6,
            display: 'block',
            textAlign: 'center',
            fontSize: 12,
        },*/

        /*featuresNumber: {
            display: 'inline-block',
            verticalAlign: 'top',
            margin: 0,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 200,
            height: 80,
        },*/

        priceGroup: {
            position: 'absolute',
            top: 268,
            paddingLeft: 20,
            paddingRight: 20,
            height: 82,
            // paddingBottom: 6,
            width: '100%',
            borderBottomLeftRadius: theme.radius.xs,
            borderBottomRightRadius: theme.radius.xs,
            borderRadius: theme.radius.xs,
            background: theme.fn.gradient({ from: theme.fn.rgba(theme.white, 0), to: theme.fn.rgba(theme.black, 0.9), deg: 180 })
        },

        priceCurrency: {
            marginBottom: 0,
            position: 'relative',
            bottom: 8,
            fontSize: 14,
            fontWeight: 400,
            marginRight: 4,
            color: theme.white,
        },

        priceAmount: {
            marginBottom: 0,
            marginTop: 0,
            fontSize: 26,
            textAlign: 'center',
            fontWeight: 400,
            color: theme.white,
        },

        priceDuration: {
            marginTop: 0,
            position: 'relative',
            top: 4,
            fontSize: 12,
            fontWeight: 400,
            color: theme.white,
        },

        propertyCardTitle: {
            // display: 'inline-flex',
            // height: 26,
            // overflow: 'hidden',

            ':hover': {
                color: theme.colors.orange[5],
            },

            [theme.fn.smallerThan('sm')]: {
                // height: '100%',
                textAlign: 'center',
            },

        },

        titleLocation: {
            display: 'inline-flex',
            width: '100%',
            overflow: 'hidden',
            gap: theme.spacing.xs,
            paddingTop: theme.spacing.xs,
            flexWrap: 'inherit',

            [theme.fn.smallerThan('sm')]: {
                justifyContent: 'center',
            },

        },

        h2: {
            fontSize: 13,
            height: 26,
            letterSpacing: 0.3,
            color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.dark[4],

            [theme.fn.smallerThan('sm')]: {
                height: '100%',
                textAlign: 'center',
            },
        },

        features: {
            display: 'inline-flex',
            alignItems: 'center',
            margin: 0,
            // marginRight: 20,
            textAlign: 'left',
            fontSize: 14,
            fontWeight: 300,
            color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.dark[4],
        },

        icon: {
            color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[5],
            marginRight: 4,
        },

        textHidden: {
            [theme.fn.smallerThan('xs')]: {
                display: 'none',
            },
        },

        textNumber: {
            marginRight: 4,
        },
    };
});
