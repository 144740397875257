import React, { ReactNode, useEffect, useState } from 'react';
import type { ActionIconProps }                  from '@mantine/core';
import { ActionIcon, Group }                     from '@mantine/core';


interface Props {
    onToggle?: (isActive: boolean) => void;
    onIcon?: ReactNode;
    offIcon?: ReactNode;
    props?: ActionIconProps;
    initialState: boolean;
    loading?: boolean;
}

export const CToggle: React.FC<Props> = ({
                                             onIcon,
                                             offIcon,
                                             onToggle,
                                             initialState,
                                             loading: _loading,
                                             props
                                         }) => {

    const [active, setActive] = useState(false);

    const toggleState = () => {

        setActive((favorite) => {
            return !favorite;
        });

        onToggle(active);
    };

    useEffect(() => {

        if (initialState) {
            setActive(initialState)
        }

    }, [initialState]);

    return (
        <Group position="center" my="xl">
            <ActionIcon
                loading={_loading}
                {...props}
                onClick={() => {
                    // console.log(active, 'activeSIM');
                    toggleState();
                }}
            >
                {/*{JSON.stringify(active)}*/}
                {active ? offIcon : onIcon}
            </ActionIcon>
        </Group>
    );

};
