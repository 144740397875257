import React, { useState }                                                  from 'react';
import { IconHeartMinus, IconHeartPlus }                                    from '@tabler/icons';
import { CToggle }                                                          from '@core/components/common/CToggle';
import { filterCheckPropertiesByIds, filterPropertiesByIds }                from '@utils/dxb-utils';
import useDxbTheme                                                          from '@hooks/use-dxb-theme';
import useUserData                                                          from '@features/common/hooks/use-user-data';
import { useRemoveUserFavPropertyMutation, useSaveUserFavPropertyMutation } from '../../generated/graphql';


interface Props {
    propertyId: string;
    isFav: boolean;
}

export const FavButton: React.FC<Props> = ({ propertyId: _propertyId, isFav }) => {

    const { isDark } = useDxbTheme();

    const { getUserId, getUserPropIds, setUserPropIds } = useUserData();

    const [isLoading, setIsLoading] = useState(false);

    const [saveUserFavProperty] = useSaveUserFavPropertyMutation();
    const [removeUserFavProperty] = useRemoveUserFavPropertyMutation();

    const removeFav = () => {
        setIsLoading(true);

        removeUserFavProperty({
            variables: {
                userId: getUserId().toString(),
                propertyId: _propertyId
            }
        })
            .then(async ({ data }) => {

                // console.log('D>> ', data);
                setIsLoading(false);

                if (data.removeUserFavProperty.error) {
                    console.error(`Error ${data.removeUserFavProperty.error}`)
                }

                //REMOVE THE ID FROM THE LOCAL STATE
                if (getUserPropIds()) {

                    const ids = filterPropertiesByIds(_propertyId, getUserPropIds());

                    // console.log(ids, 'REMOVE IDs')

                    setUserPropIds(ids)
                }

            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                // message.error("Error unable to reach server " + error)
            });
    }

    const addFav = () => {
        setIsLoading(true);
        saveUserFavProperty({
            variables: {
                userId: getUserId().toString(),
                propertyId: _propertyId
            }
        })
            .then(async ({ data }) => {

                // console.log('D>> ', data);
                setIsLoading(false);

                if (data.saveUserFavProperty.error) {
                    console.error(`Error ${data.saveUserFavProperty.error}`)
                }

                //UPDATE THE LOCAL STATE
                if (getUserPropIds()) {

                    const isAlreadyThere = filterCheckPropertiesByIds(_propertyId, getUserPropIds())

                    if (!isAlreadyThere) {

                        const oldIds = getUserPropIds();
                        const updated = [
                            ...oldIds,
                            {
                                __typename: 'PropertyIDS',
                                property_id: _propertyId
                            }
                        ]

                        // console.log(oldIds, 'oldIds')
                        // console.log(updated, 'updated')

                        setUserPropIds(updated)
                    }

                }

            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                // message.error("Error unable to reach server " + error)
            });
    }

    return (
        <CToggle
            initialState={isFav}
            loading={isLoading}
            onIcon={<IconHeartPlus color={isDark ? 'orange' : 'orange'} size={32} stroke={1.5}/>}
            offIcon={<IconHeartMinus color={isDark ? 'orange' : 'orange'} size={32} stroke={1.5}/>}
            onToggle={(state) => {
                if (state) {
                    removeFav()
                }
                if (!state) {
                    addFav();
                }
            }}
            props={{
                variant: 'transparent',
                size: 'lg',
            }}
        />
    );

};
