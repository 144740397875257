import { useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useMediaQuery }                          from '@mantine/hooks';


function useDxbTheme() {

    const theme = useMantineTheme();
    const { colorScheme } = useMantineColorScheme();

    const isDark = colorScheme === 'dark';
    const isLight = colorScheme === 'light';

    const isSmallScreen = useMediaQuery('(min-width: 500px)');

    return {
        isDark, isLight, colorScheme, theme, isSmallScreen
    };
}

export default useDxbTheme;
